import {
    $, addAction, addFilter, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $els = $('.content-section').slice(1);
    const $footer = $('.footer-nav');

    function getTotalHeight() {
        let totalHeight = 0;
        $els.add($footer).each((index, el) => {
            totalHeight += $(el).outerHeight();
        });
        return totalHeight;
    }

    addFilter('css-vars/register', (styles) => ({
        'footer-height': () => `${getTotalHeight()}px`, ...styles,
    }));
});
